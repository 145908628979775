
import React, { useEffect } from "react"
import { news } from "data/news/0"
import { ago } from "@/lib/utils/ago"
import { config, locale } from "@/lib/config"
import Link from "next/link"
import showdown from 'showdown'
import parsehtml from '@/lib/utils/parsehtml'
import markdownStyles from './markdown-styles.module.css'

export function ShowNews({
    shownews
   ,set_shownews
   ,ssr_data_tribe
  }){

    var converter = new showdown.Converter()
  

   return <>
   {/* //news */}

  
           
  
  
           <div className={`${shownews=="1" ? " opacity-100 block" : " opacity-0 hidden"} flex-1 transition  transition-slowest ease duration-500`}>
             <div className="border-b">
               <div className="">
  
  
               {/* <section 
                  aria-labelledby="announcements-title"
                  className="bg-gray-50 p-4"
                > */}
                <div className="rounded-lg bg-gray-900 overflow-hidden border">
                  <div className="p-6">
                    <div className="flow-root">
                      <ul className="-my-5 divide-y divide-gray-700">
                         { news(ssr_data_tribe).slice(0, config.newsperdashboard)?.map((e,i) =>

                          
                          <li className="py-5" key={i}>
                            <div className="flex flex-row">
                              <div className="flex-1">
                                
                                <h3 className="text-sm font-semibold text-white">
                                  
                                      <span className="text-gray-300 italic">
                                        {e.v}
                                      </span>
                                        <span className={` ${markdownStyles['markdown']}`}>
                                          {parsehtml(converter.makeHtml(e?.headline))}
                                        </span>
                                      {e.md_file == '1' &&
                                        <span className="border no-underline rounded-md ml-5 px-4 py-2 mt-5 cursor-pointer hover:border-gray-600 focus:outline-none">
                                          more details
                                        </span>
                                      }
                                  <Link
                                    href={`${locale()}/news/${e.id}/${e.slug}`}
                                    className="cursor-pointer group focus:outline-none mt-2">
                                    
                                      Permalink
                                    
                                  </Link>
                                </h3>
                              </div>
                              <div
                                className="flex-0 text-md  text-gray-600"
                              >
                                {ago(e.crdate,+new Date)[0]}
                              </div>
                            </div>
                            
                          </li>
                          

                      )}
                  
  
                      </ul>
                        </div>
                        <div className="mt-6">
                          <Link
                            href={`${locale()}/news`}
                            className="cursor-pointer w-full flex justify-center items-center px-4 py-2 border  shadow-sm text-sm font-medium rounded-md 
                           
                            text-gray-200 bg-gray-900 hover:bg-gray-900 border-gray-600 
                            ">
                            
                              View all
                            
                          </Link>
                        </div>
                      </div>
                    </div>
                  {/* </section> */}
  
  
               </div>
             </div>
           </div>
   
   </>;
  }