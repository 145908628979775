export default function BannerWrapper({children}) {
    return (<>
                <div className={` lg:shadow-inner flex items-center justify-between border-b   p-4
                                bg-gray-700 border-gray-500 
                `}>
                    <div className="flex-1 flex items-center justify-between ">
                        {children}
                    </div>
                </div>
            </>)
    }