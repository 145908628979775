import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { bumber } from "@/lib/utils/bumber"
import Link from "next/link"
import React from "react"
import { DashboardHeadline } from "@/components/dashboard/dashboard-headline"

export default function SectionDividerCollapse({
     itemstate
    ,set_itemstate
    ,storagekey
    ,headline
    ,barextra //statbar
    ,link_href=''
    ,buttontext=''
    ,buttonicon=''
    ,allowcollapse=true
    ,children

}) {

    return <>
            <div 
                className={`group pb-3 flex items-center justify-between border-b pt-4
                            bg-gray-600  border-gray-400 
                            ${allowcollapse 
                                ? ` hover:bg-gray-500` 
                                : ""}
                        `}
            >
                <div 
                    className="flex-1 flex items-center justify-between "

                >
                    <div
                        className={`flex-1 flex ${allowcollapse ? "cursor-pointer " : ""} mr-2`} 
                        onClick={()=> {
                            if (allowcollapse) {
                                localStorage.setItem(storagekey,bumber(itemstate))
                                set_itemstate(bumber(itemstate))
                            }
                        }}
                    >
                    <DashboardHeadline 
                        headline={headline}
                        />
                    </div>

                    {barextra}

                    {buttontext !== '' &&
                    <span className="flex-0 inline-flex rounded-md mr-2">
                        <nav className="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Options">
                        
                            <Link
                                href={link_href}
                                className={`
                                cursor-pointer relative inline-flex items-center px-2 py-2 rounded-md border text-sm font-medium
                                 border-gray-500 bg-gray-600  hover:bg-gray-500 hover:border-gray-400 text-gray-200
                                `}>

                                <span className="mx-1 flex-1 flex whitespace-nowrap">
                                    {buttontext}
                                </span>

                            </Link>
                        </nav>
                    </span>
                    }
                    {allowcollapse &&
                    <span className="inline-flex flex-0 rounded-md mr-2">
                        <nav className="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Options">
                        
                            <a 
                            className={`
                            cursor-pointer relative inline-flex items-center px-2 py-2 rounded-md border text-sm font-medium  
                            border-gray-500 bg-gray-600 text-gray-300 group-hover:bg-gray-500 group-hover:border-gray-400
                            `}
                            onClick={()=> {
                                    localStorage.setItem(storagekey,bumber(itemstate))
                                    set_itemstate(bumber(itemstate))
                                }}
                            >
                            
                            
                            <span className="flex flex-0">
                                <SvgJsx 
                                    type='fill' 
                                    icon={itemstate=="1" ? `chevron-up-sm` : `chevron-down-sm`} 
                                    className=' w-5 h-5 flex-0 my-auto' 
                                    title={itemstate=="1" ? `close` : `open`} 
                                />
                                </span>
                            </a>
                        
                        </nav>
                    </span>
                    }

                </div>
            </div>

            <div className={`border-b ${(itemstate=="1" || !allowcollapse) ? "block" : "hidden"}`}>
                {children}
            </div>  
    </>;
}