/*
data/news/0



*/
export const upcoming = (ssr_data_tribe) => [
    {
        id: 37
        ,v:`0.9.5.4`
        ,crdate: `1/16/2022`
        ,slug: ``
        ,headline:` `
        ,bodytext: 
        `
        - updates to profile stats
        `
        ,author: ssr_data_tribe?.brand_author
        ,md_file:`0`
        },
    {
id: 37
,v:`0.9.5.3`
,crdate: `1/16/2022`
,slug: ``
,headline:` `
,bodytext: 
`
- restored the listening tab
- realtime listener numbers and member list during broadcasts
- fixed test mode bug in web broadcaster
- updated site to use latest version of react and node
- swapped out the react player for a new one (fixed pause issue)
- some other bugs...
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
},
{
id: 37
,v:`0.9.1`
,crdate: `1/16/2022`
,slug: ``
,headline:` `
,bodytext: 
`
- fixed bug preventing follower management (now in user settings)


- new beta feature - if you do a live stream using youtube video, you can now embed the video window on prankcast as well to be visible in chat (desktop only). if you want to use this feature let me know.

- the chat bot now appears in the chat list (when activated)
- chat flairs now show up in the chat list.
- fixed regex issues in chat preventing some links from showing properly
- major changes to chat layout
- fixed bugs making images warp shrink on mobile screens
- fixed bugs making user flair wrap awkwardly on mobile screens
- added bold and italic and linebreak support back to markdown in chat
- fixed bots 
- fixed bot announcements so they treat different channels appropriately
- fixed bot default names

Thanks to Riddle for helping with the regexp!

- individual toggles for different image types in chat have been added
- markdown is no longer available in chat
- images can be zoomed in by clicking on them
- huge refinement to chat performance
- removal of markdown in chat.
- Password fixes
- Added discord signup invitation during onboarding flow
- unified settings into one single menu instead of that awkward double menu state from before
- Added a visible indicator when outgoing show alerts are disabled, so the host is immediately aware.
- Fixed issue with private chats showing up in multiple windows
- images are now smaller on screen

Next
- Custom bot announcements name
- move over to actual images (instead of markdown)

Bots
- Fixed the bot itself
- Customize the bot name
- Customize the bot announcement list 

--ANNOUNCED
- Serious performance fixes for chat, mostly behind the scenes.
- Fixed a bug preventing the contributor icon from appearing to guests.
- Fixed the gap between chat and the chat form.
- some reformatting of gifs in chat to keep them smaller and limit chat flooding.
- Refactored the entire website so the codebase can be easily franchised for other verticals.
- Added a confirmation to unfollowing users to avoid accidental unfollows.

Misc
- fixed a bug with user moderation not working
- fixed the position of the chat form so it's always visible.

Promotion Changes
- all broadcasters can now be seen on the homepage if their notifications are on. 
- Only featured broadcasters will receive a public discord notification, however all followers will still receive a notification.
- Moving forward, broadcasters will only be in the featured column when live if they are new and building an audience with our help, or have been around a while and have consistently demonstrated high quality shows.
- To determine "high quality shows" we measure listener engagement (eg. number of listeners, number of chatters and participation in chat from real listeners with trusted accounts. 
- Good (working) audio, relevant content to the general audience  and actively interacting with your audience in positive ways during shows are recommended here. 
- I'm going to be spending some advertising budget and want to make sure that we are leaving a great first impression on hosts.
- If your broadcast is no longer in the Featured column, it can still easily be found in the All column. Improving your show quality will get you put back into the featured tab.

- fixed drawing bug that would sometimes not post the last line drawn when saved to chat
- fixed security holes on websockets
- some sections of the site are now being prepped for contributor only, as i start to make the shift towards a business model.
- if there is a premium feature you need and you cannot afford to become a contributor, just dm me privately.
- added a staff activity log. you can use this in case a change happens on your end and you want to understand how. The data needs to be expanded and drilled down into - it's ugly but should be understandable.
- drawing tool added to chat
- tip jar security issue
- chat form security issue
- playground issue
- added proper file downloading.
- diteched verified tags. it's contributors only moving forward.
- added a custom email confirmation page for a nicer signin / registration experience
- Fixed a bug triggering an alert during registration
- Fixed a bug in settings that wouldn't really stop a user from discarding changes they inputted if they didn't mean to leave the screen.
- Ditched the ugly default browser confirm alerts in favor of a custom solution.
- fixed bot suggest bug
- fixed bug where listener accounts won't refresh in chat
- fixed a bug where non-logged in users would have issues seeing bot messages
- patched a bug that would sometimes ignore the first user click when a user tries to login.
- added some additional security measures.
- closed some potential security holes.

- changed the discord notification cooldown from 22 hours to 8 hours
- fixed the overflowing of messages in chat - hovers will no longer move the message around.
- added some additional polish to the new chat to make it less jumpy when typing.
- Fixed stats in chat
- You can now also edit the broadcast title in the stats section during chat.
- changed the default user type to guest when adding user names to your broadcast.
- Added a way to customize user join announcements and public bot replies in the chatroom, in case it annoys you during the show.
- Raw message data is now showing up in chat. 
- You can like/save/report bot messages now.
- You can now like individual messages on mobile.

## Misc
- Completed migration for profile links on behalf of all users. Contacts and Friends sections are now consolidated with Links and will no longer be accessible as separate configurations in the user settings.
- Fixed a mobile responsive issue on the profile pages.
- Posts are now infinitely scrollable.
- Posts can be stickied now.

- fixed issue to make sure files are completely deleted when the broadcast is deleted.
- fixed issue preventing replay after a showreel item ended.


- Posts comment moderation, including settings to disable or require moderator approval.
- New setting for posts allows it to be disabled entirely.
- New setting for post comments allows it to globally or locally turn off comments.
- slowed down the polling on non-broadcasters since we dont need to see if they are online as often as broadcasters. this should improve site performance.



## Bumpers!
- Added bumper configuration
- Added ${ssr_data_tribe?.brand_name} bumpers to intro and outro.


- Chat history pages are reversed and are now permaurls so we dont need to constantly recalculate everything (which was a massive performance burden)


## Content Uploads and Posting!
- You can now update your followers about changes to your schedule or whatever the hell you want.
- This includes your portfolio of furry images, audio uploads, whiny public blog posts, and more!
- Similar to schedules and broadcasts, you can track all of this on a single screen, or just track the folks you follow.
- Comments and likes!

## Broadcast defaults

- If you have a show scheduled, you can now set broadcast defaults.
- All broadcasts that you trigger from that show schedule will use your defaults if you created them.
- For example, if you always want to link your website in your broadcast description, just save it in your show schedule defaults and it will auto import as editable default text by each broadcast you create.
- You can currently add a title and description. Show guests and show graphic defaults will be pulled from the show description, for now.
- I realize this is probably confusing I'll make a video for this when I have time.

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
]


export const news = (ssr_data_tribe) =>    
[ 

    {
        id: 37
        ,v:`0.9.5`
        ,crdate: `5/11/2022`
        ,slug: `chat-v4-and-posts`
        ,headline:`Chat v4, Offline Posts, and RIP Light Mode`
        ,bodytext: 
        `
Simplified User Interface

I've taken major steps to simplify and improve Prankcast. First of all, there were a lot of dead-end pages that were rarely used. This


** RIP Light mode**
- There's no more dark mode toggle, because dark mode is all there is. I should have done that last year.

**New Homepage**
- I've combined upcoming schedules and live shows into one single view.
- Posts are now front and center
- Posts will be promoted based on user interest, instead of being included in a featured list.

**New User Profile Pages**
- In both, the emphasis is now on Posts. Posts are a combination of showreels, uploaded audio clips, text updates, images, and anything else you want to share with your followers or let you promote yourself to new audiences.
- Posts can be used for example to share a clip to the site homepage for more exposure, or just to let your listeners on your profile page know that a show will be starting late. Use it as you see fit.
- There's also an Audio only view, so you can get directly to the good stuff with less clutter.
- Podcasts will now incorporate all audio posts, including Showreels and any uploaded clips.

**Chat v4 is live**
- Completely overhauled for the fourth and hopefully final time.
- The layout is way more intuitive and more responsive on both desktop and mobile. Open the panels to see the chat options
- I fixed some issues that caused lag and battery drain on slower devices.
- You can now reply to all messages directly, and maintain a link to those messages.
- While I was at it, I built this in such a way that we can add direct messages between users next.
- You can see a list of listeners during broadcasts. [Note this list may be buggy]
- new test-only feature - if you send a video live stream to YouTube, you can embed the video in chat (desktop only). If you want to use this feature, let me know.

Settings UX Improvement
- fixed bot default names so they can be customizable
- individual toggles for different image types in chat have been added
- unified settings into one single menu instead of that awkward double menu state from before
- Added a visible indicator when outgoing show alerts are disabled, so the host is immediately aware.

Misc Fixes

- Follower management has been moved to Profile Settings. You can now assign mods to help you manage Follow requests.
- the chat bot now appears in the chat list (when activated)
- chat flairs now show up in the chat list (still wonky)
- fixed regex issues in chat preventing some links from showing properly
- fixed bugs making images warp shrink on mobile screens
- fixed bugs making user flair wrap awkwardly on mobile screens
- added bold and italic and linebreak support back to markdown in chat
- fixed bots 
- fixed bot announcements so they treat different channels appropriately

Thanks to:
- @Criefaux for going above and beyond in testing the new features and giving feedback.
- @Riddle for helping with the regexp!

Promotion Changes
- all broadcasters can now be seen on the homepage if their notifications are on. 
- Only featured broadcasters will receive a public discord notification, however all followers will still receive a notification.
- Moving forward, broadcasters will only be in the featured column when live if they are new and building an audience with our help, or have been around a while and have consistently demonstrated high quality shows and a good experience on the site.
- To determine "high quality shows" we measure listener engagement (eg. number of listeners, number of chatters and participation in chat from real listeners with trusted accounts. 
- Good (working) audio, relevant content to the general audience and actively interacting with your audience in positive ways during shows are recommended here. 
- I'm going to be spending some advertising budget and want to make sure that we are leaving a great first impression on hosts.
- If your broadcast is no longer in the Featured column, it can still easily be found in the All column. Improving your show quality will get you put back into the featured tab.

- fixed drawing bug that would sometimes not post the last line drawn when saved to chat
- fixed security holes on websockets
- some sections of the site are now being prepped for contributor only, as i start to make the shift towards a business model.
- if there is a premium feature you need and you cannot afford to become a contributor, just dm me privately.
- added a staff activity log. you can use this in case a change happens on your end and you want to understand how. The data needs to be expanded and drilled down into - it's ugly but should be understandable.
- drawing tool added to chat
- tip jar security issue
- chat form security issue
- playground issue
- added proper file downloading.
- diteched verified tags. it's contributors only moving forward.
- added a custom email confirmation page for a nicer signin / registration experience
- Fixed a bug triggering an alert during registration
- Fixed a bug in settings that wouldn't really stop a user from discarding changes they inputted if they didn't mean to leave the screen.
- Ditched the ugly default browser confirm alerts in favor of a custom solution.
- fixed bot suggest bug
- fixed bug where listener accounts won't refresh in chat
- fixed a bug where non-logged in users would have issues seeing bot messages
- patched a bug that would sometimes ignore the first user click when a user tries to login.
- added some additional security measures.
- closed some potential security holes.

- changed the discord notification cooldown from 22 hours to 8 hours
- fixed the overflowing of messages in chat - hovers will no longer move the message around.
- added some additional polish to the new chat to make it less jumpy when typing.
- Fixed stats in chat
- You can now also edit the broadcast title in the stats section during chat.
- changed the default user type to guest when adding user names to your broadcast.
- Added a way to customize user join announcements and public bot replies in the chatroom, in case it annoys you during the show.
- Raw message data is now showing up in chat. 
- You can like/save/report bot messages now.
- You can now like individual messages on mobile.

## Misc
- Completed migration for profile links on behalf of all users. Contacts and Friends sections are now consolidated with Links and will no longer be accessible as separate configurations in the user settings.
- Fixed a mobile responsive issue on the profile pages.
- Posts are now infinitely scrollable.
- Posts can be stickied now.

- fixed issue to make sure files are completely deleted when the broadcast is deleted.
- fixed issue preventing replay after a showreel item ended.


- Posts comment moderation, including settings to disable or require moderator approval.
- New setting for posts allows it to be disabled entirely.
- New setting for post comments allows it to globally or locally turn off comments.
- slowed down the polling on non-broadcasters since we dont need to see if they are online as often as broadcasters. this should improve site performance.



## Bumpers!
- Added bumper configuration
- Added ${ssr_data_tribe?.brand_name} bumpers to intro and outro.


- Chat history pages are reversed and are now permaurls so we dont need to constantly recalculate everything (which was a massive performance burden)


## Content Uploads and Posting!
- You can now update your followers about changes to your schedule or whatever the hell you want.
- This includes your portfolio of furry images, audio uploads, whiny public blog posts, and more!
- Similar to schedules and broadcasts, you can track all of this on a single screen, or just track the folks you follow.
- Comments and likes!

## Broadcast defaults

- If you have a show scheduled, you can now set broadcast defaults.
- All broadcasts that you trigger from that show schedule will use your defaults if you created them.
- For example, if you always want to link your website in your broadcast description, just save it in your show schedule defaults and it will auto import as editable default text by each broadcast you create.
- You can currently add a title and description. Show guests and show graphic defaults will be pulled from the show description, for now.
- I realize this is probably confusing I'll make a video for this when I have time.

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
},
{
id: 36
,v:`0.9.0`
,crdate: `1/13/2023`
,slug: `chat-bots`
,headline:`Chat v3 - Speed, Bots, Flair and Uploads`
,bodytext: 
`We're officially at v0.9!

This Major update is all about *Chat: Speed, Bots, Flair and Uploads*

This is Part 1 of the Patch notes and only includes publicly available changes. Some of the functionality that will be listed in Part 2 has been in the works for months now, so I'm so excited to get it out the door. There's a bunch more changes coming with Part 2 of this update! I also never officially announced some older patch notes, so I added those as prior posts at ${ssr_data_tribe?.server_website}/en/news to help catch you up.

On to the good stuff...

## Chat v3 is live

**Speed improvements**
- Completely rewrote the core of the chat application to make it feel more responsive on all devices.

**Bots are live!**
- You'll see a bot posting memes and channel announcements in the room.
- Hosts can use chat filters in combination with the bot to really randomize the things it says.
- Type /help in the chat room to get dm'd a list of global chat commands. Some examples: /joke | /rules | /define word
- Hosts can build their own custom chat triggers to make the bot say silly (or productive) things when certain words are triggered.

**Username flair**
- Add custom flairs to your follower's names. You can customize this or use templates. This is a good option to call out new supporters or make funny in-jokes.

**Chat uploads**
- [Contributor-only feature] You can now upload in chat or access previously uploaded images! This feature is a new tab in the image menu where you post gifs.

**Other misc chat tweaks**
- You can upvote individual messages instead of just the parent (desktop only for now).
- Users can like their own comments now, because you do you.
- Welcome message now has the user's profile inks inside it as a new tab, to make scrolling in chat easier. The site footer was also removed...
- Broadcaster stats are being calculated in a new way and have been moved into a tab in the welcome screen. this could be buggy - please let me know if you see any wonkiness.
- chat is now full screen by default. you can make it even taller in the Chat Options menu.
- You can now browse chat while logged out again.
- Note: Chat history is temporary disabled while I work on compatibility with the new chat.

## Profile Page improvements
- Profile Links now has options for headers and spacers.
- As such, Profile Links, Contacts and Friends can now be consolidated into one single menu and will all be moving under the Links form.
- Contacts and Friends will be deprecated and and deleted on Jan 31, 2023, so please move them over beforehand. I'll try to help anyone whose profile I see has the old stuff on them.
- A live preview of the links section will appear along side the form, so you can see how your page will look as you edit.
- At the bottom, you will now see a templates option. If you use this it will reset your form and build a template you can modify (listener or creator templates).
- To edit this, go into Settings > Profile . ${ssr_data_tribe?.server_website}/app/settings/profile?panel=userprofile

## Broadcaster improvements
- Completely rewrote the 3rd party broadcasting app section to offer specific tutorials, per app.
- You can now use non-SSL compatible apps to broadcast to ${ssr_data_tribe?.brand_name}. I have added additional app options to the 3rd party section.
- Added a simple broadcaster onboarding guide page for newly added broadcaster accounts. You can see it here: ${ssr_data_tribe?.server_website}/en/guide
- Added a warning message when a user does not use ${ssr_data_tribe?.brand_name} servers to broadcast in their settings, since a couple hosts got confused on this.
- when you disable outgoing notifications you will not show up as live on the homepage anymore - so you can better test privately. You will still show up as live if someone visits your profile page directly.
- Added a new region to our broadcasting host to make west coast broadcasters have faster connections.

## Personal warrant canaries
- Personal warrant canaries have been added and will be incremented whenever a patch note change goes live. You can see your personal warrant canary status on your personal settings page at the bottom.
- Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}

,{
id: 35
,v:`0.8.6`
,crdate: `12/10/2022`
,slug: `filter-shenanigans`
,headline:`Filter shenanigans`
,bodytext: 
`Minor update: Some changes related to the way folks interact with filters.

## Chat tweaks
- Hosts can now disable filters for the entire chat with a simple toggle, without deleting any elements. This allows hosts to turn filter-mode on/off at will for fun.
- You can now disable all host chat filters if they annoy you (for your own viewing only).
- "Zalgo text" was modified so it will still show up but shouldn't slow down site performance or overlap into other people's messages.

## Broadcasts
- Listener count was added to the chat member button (when applicable).
- temporarily disabled the ability to start the show with creating a broadcast title. I need to rewrite this functionality since it would sometimes get stuck and frustrate hosts. For now it will just broadcast directly and you can change it once the show is live.

## Misc
- For performance reasons, accounts can now have a hard limit on the number of staff member accounts. I arbitrarily chose 10 because that seemed reasonable. If your account already had more than this, you're grandfathered in until you make changes. If this number is too low, reach out to me. 
- thumbnail avatars will now zoom in when they arent square, so there's no ugly whitespace

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}

,{
id: 34
,v:`0.8.5`
,crdate: `11/1/2022`
,slug: `performance-improvements`
,headline:`Performance improvements`
,bodytext: 
`Minor update: Lots of bug fixes - the most visible ones are included below.

# Misc
- disabled chat browsing unless logged in due to performance issues
- fixed bug that would add a moderator block to the moderators personal chat room
- fixed issue preventing certain follower pages from showing up correctly
- fixed bug preventing staff activity log from displaying 
- "Zalgo text" posted in chat was modified so it will still show up but shouldn't slow down site performance or overlap too much into other people's messages.

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}

,{
id: 33
,v:`0.8.4`
,crdate: `10/10/2022`
,slug: `admin-improvements`
,headline:`Admin improvements`
,bodytext: 
`
Minor update: A bunch of miscellaneous fixes, tweaks and upgrades..., many of which were just tools to help me better administer the site.

## Misc
- Added backgrounds to play buttons
- Fixed schedule edit menu options
- kill stream api functionality was added, so that hosts can end their own streams if something breaks and the stream doesn't automatically end.
- added emails to admin actions so users are notified about account changes (eg. being granted broadcast credentials)
- fixed bug turning on audio when someone hits the X on a play reminder banner.
- fixed mobile lock screen pause/play bug preventing those buttons from being respected.

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
},
{
id: 32
,v:`0.8.3`
,crdate: `9/13/2022`
,slug: `improved-chatroom-security`
,headline:`Improved Chatroom Security`
,bodytext: 
`
Bigger update coming soon, but in the meantime I've taken some steps to make ${ssr_data_tribe?.brand_name} chat more secure and did not want to wait on this release: 

## Chatroom Security

- You can no longer put markdown links in the chatroom in this format \`[text](url)\`. It represented a risk of users making the text look like a false url and linking it to something unsafe. For example: \`[htp://ebay.com](htp://veryverybadsite.xyz)\`
- All http urls will still be linked, but will now take you to a ${ssr_data_tribe?.brand_name} intermediate page where you are given a chance to inspect the URL and make sure you want to click through to it. This should prevent people from accidentally clicking a URL in a fast-moving chatroom and unintentionally visiting an unsafe page.
- All images are now run through a proxy on duckduckgo before being displayed in chat. This should prevent ip-logger sites linked as image urls from being able to grab the personal IP addresses of individual users in chat.

## Misc

- Fixed a bug preventing scheduled broadcast drafts from being edited in the Web Broadcaster App
- Fixed podcast sorting issue
- Built an import script to pull in and host old Mixlr showreel files from interested users
- Wrapping long chat links so chat doesnt break when a big string is posted

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,
{
id: 31
,v:`0.8.2`
,crdate: `8/24/2022`
,slug: `hiding-stats-temporarily`
,headline:`Temporarily Hiding Broadcast Stats from non-hosts`
,bodytext: 
`
Minor update: just letting folks know that I'm hiding profile stats temporarily. They were causing some performance slowdowns since they are calculated separately for each user accessing the page, which is a very dumb approach on my end. I'll fix it over the weekend and put things back. In the meantime, the stats will only be visible to the hosts and admins with broadcast rights.

# Misc
- fixed bug showing wrong follower count and link in notification email
- Fixed wrong edit links on profile pages (when authorized profile mods try to make changes on a host's profile)
- More admin tools to help ${ssr_data_tribe?.brand_name} staff better administrate the site (seo)
- Backend prep work to allow bulk file imports from external sources

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,{
id: 30
,v:`0.8.1`
,crdate: `8/10/2022`
,slug: `multiple-tip-jar-options`
,headline:`Multiple Tip Jar Options`
,bodytext: 
`
## Tip Jar
- You can now add multiple payment options to the Tip Jar.
- They will appear as a list of options when the Tip Jar is clicked on. 
- If only one option is given, the user will just be brought to that option immediately without an extra click.

## Misc Fixes
- Made a minimum showreel threshold of 1 hour to be featured on the homepage.
- Fixed showreel toggle states.

## Admin Tools
- Added a number of admin tools to help me better administrate the site.

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,
{
id: 29
,v:`0.8.0`
,crdate: `8/5/2022`
,slug: `showreel-changes`
,headline:`Showreel changes`
,bodytext: 
`
The next few updates will be pushed out incrementally (and hopefully rapidly). The theme of v0.8 is content and I'll be introducing more functionality focused on managing your content, including custom uploads, audio clipping, content stats, the ability to embed audio files externally and text posts.

## Homepage changes
- The most recent 3 showreels are now on the homepage. This will hopefully help new listeners discover hosts using the showreel feature.
- Currently live shows will still take precedence (and are hidden when nobody is live).
- Shows that haven't been active in a while will be removed from the homepage. 
- I will also be cycling out featured broadcasters who are not active and have not indicated plans to be.
- I'm happy to put them back when you're ready to go live again. Just reach out and let me know.

## Showreel Changes
- Showreels now have a horizontal full-width row layout. It's responsive on mobile.
- The seek bar is much bigger and actually functional.
- Clicking the title will now take you to the permaurl for the showreel. It will feature description info (and in a near-term release, statistics for the broadcast).
- You can see more information by clicking the new Broadcast Info option in the More menu on the right.
- Staff with Content rights can also toggle the showreel to publish it.

## Misc
- Made chat avatars update with the latest entrant from left to right
- I was contracted by Vagibond Inc to launch their new website at https://vagibond.com.

## Canaries hatched since the last update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
},
{
id: 28
,v:`0.7.2`
,crdate: `8/1/2022`
,slug: `raids-search-and-more-oh-my`
,headline:`Raids, Search, and more oh my`
,bodytext: 
`

-fixed raids
- added following to posts
- added homepage playing instead of needing a double click
- anyone can create posts now.

Getting there! This is a mix of fun features (raids), long overdue features (user search), and some more critical components (custom notification settings) that will let me build more functionality ()

## Chat raids
- Show hosts: Choose Start Chat Raid from the chat menu (top right corner of chat) to initiate a raid.
- You can also simply type "/raid @username" into your chat and instantly send your users over to that user's chat (Just swap out the word "username" with the username).
- If they are listening to your show, they will still be able to listen to you until they manually end the stream or manually begin to listen to the user you have sent them to.
- Chat participants can opt out of participating in chat raids under Personal settings > Advanced Features.

## Notification updates
- Behind the scenes changes to how notifications go out. This will allow for flexible customization about what notifications users opt into or out of.
- Go into your Personal -> Notifications settings to customize them.
- I reenabled Follower notifications for those who want them. Feel free to disable this within your Personal Settings.
- In an upcoming release, I will also make it so that you can tweak individual notifications at a host level.

## API changes
- Added Guest information to API queries
- Added Guest Json information to embeddables

## Embeddable Player
- Added detailed About section with broadcast context.
- Added a script to stop playing audio from pausing when the display turns off.
- Minor layout tweaks (moved all settings and about information into a More menu)

## User Search
- You can now search for users directly. Click the ${ssr_data_tribe?.brand_name} logo and choose Search from the drop down menu.
- You can also trigger it from the homepage by clicking the search icon next to Featured broadcasters, or clicking the search icon on the User Directory page.

## Chat
- Added a flood cooldown to all chat actions. This will stop them from being abused to annoy hosts and will keep my costs normal, since im charged based on usage.
- While I was at it, I laid in the foundation for individual users to be individually rate limited in the future (it's currently a global value). 
- This way if a particular user seems to needs a longer cooldown period than others, we can automatically provide it.
- Fixed issue showing duplicate avatars when a user has multiple browsers open.
- Removed the live broadcast stat placeholder "Total Chat: 0" that I've been too lazy to remove to date.

## Misc
- only broadcasters have "off air/live" tags by their profiles now

## Thanks
Huge thanks to all the [Contributors](/contribute) for enabling this project to GO GO GO. 

*Canaries hatched since the last update: 0*
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,
{
id: 27
,v:`0.7.1`
,crdate: `7/21/2022`
,slug: `staff-roles`
,headline:`Staff roles and chat fixes`
,bodytext: 
`
## Staff Roles
- You can now assign specific roles to Staff members. These correspond to the tabs under Settings > Staff.
- This means you can assign a chat mod who can only help with chat-related parts of your show. Previously staff had full control everywhere.
- Edit buttons will now appear for appropriate staff contextually in the profile drop down.
- Staff with Profile edit permissions can now edit directly from a user's profile.
- Staff can now add other staff, (if given Staff permission access). This can be accessed under Settings > Staff.
- Staff still cannot see or change your personal information (located under the Personal tab).

## Chat Upgrades
- Reporting and saved screens now have a button that will take you back to chat. they also will not show new messages.
- Chat history tab is added, with pagination.
- Chat settings can now be accessed from the navbar Chat dropdown (if you're an owner or have chat permissions)
- A major memory leak in chat was fixed. This contributed to some web broadcaster instability so will help make this more stable.
- The timer in chat now works correctly. 
- Full screen toggle and chat welcome message buttons are now consolidated into a single More menu button in the top right of chat. 
- It also has a Chat settings option inside for chat mods. Fututre additional functionality will be added there.
- moved moderation options into a submenu inside chat.
- Added a visual indicator when a message was filtered (the more menu appears in red). 
- You can see the original unfiltered message by clicking the more menu > view raw message.
- Added message user context, and timestamps to the chat menu.
- Fixed bug preventing the members list from updating.
- Fixed bug where an open chat menu's context would change if a new message came in.
- fixed bug removing the delete option from chat for your own messages
- Fixed reporting bug issue - now admins and chat mods can see reports

## Audio Upgrades
- Implemented a wake script to keep mobile devices from sleeping while a show is playing.
- Lowered podcast caching time to 1 hour (was previously 24 hours).
- Volume settings are now remembered in browser memory.

## Homepage Upgrades
- Changed default sorting on homepage to be by most recent broadcast
- when a user is live, clicking their lit up Avatar will now take you directly to the live chat.

## Settings Changes
- Broke out Creator Settings into 6 tabs: Profile, Chat, Broadcast, Content, API, Staff
- Some settings items have been shuffled around to work well with the new staff categorization (eg. Showreels are now managed under Content)
- Added opt out toggle for adding showreels to podcasts
- fixed bug showing the wrong name in the ignore list
- fixed bug showing an error message when doing user moderation, even though it actually worked.

## Misc
- Fixed bug allowing some emails to have spaces in them
- Bunch of further SEO improvements 
- Soooo many bug fixes. Who wrote this shitty code anyway??

## Up Next:
- Refined broadcast & chat stats
- Refined notifications control
- Clipping API
- Showreel design upgrades
- Staff activity log
- Text Posts

##  Canaries hatched since the last patch notes update: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,
{
id: 26
,v:`0.7.0`
,crdate: `6/26/2022`
,slug: `chat-staff-and-more-power`
,headline:`v0.7 is live! Chat, Staff, Podcasts and more power...`
,bodytext: 
`
Oh sweet jesus, this is finally out the door. This is the biggest under the hood set of changes yet. 
Some functionality has already been quietly released and tested by hosts, but with this patch notes, I'm making it official:

## Broadcaster warning
- I'm putting this up top so it's not missed. The web broadcaster is having some memory issues and seems to crash in some browsers.
- Until further notice, I strongly recommend using third party apps to connect and broadcast to ${ssr_data_tribe?.brand_name} instead.

## Settings split
- Settings have been split into two types: personal (the first tab) and creator (everything else).
- I made this change so you can have staff help you with your creator settings, without any risk of sharing your personal settings and data.
- I've split a bunch of the settings up between these two sections. I won't list it here, but just click through to familiarize yourself with what's where.
- The Personal settings tab can only be seen and edited by you, not your staff.

## Ignored Users
- Separate from Chat blocking / timeout functionality, you can now choose to ignore users. This will mean you will not see their chat comments anywhere.
- This is different from chat blocking / timeout. Blocking will keep the user from posting in YOUR chatroom only. Ignoring users will still allow them to post, you (only) won't seem them anywhere (including other chats)
- You can manage your list of ignored users under your Personal Settings -> Advanced -> Ignored Users.

## Blocked Users
- As mentioned, this is different than ignored users and prevents people from posting in your chatroom only. This list can now be managed inside your Creator Settings -> Chat -> Blocked Users.
- You can now specify a timeout length, or set the block to be indefinite. 
- Users can now be timed out for different time periods. The number of minutes remaining will show up to the user on the chat message panel (and in the User Block admin screen for chat staff)
- Blocked users will still be able to view your chatroom. They just won't be allowed to post in it.

## Chat filters
- This was released quietly a few weeks ago, but it was never included in patch notes.
- Filters allow show hosts to automatically replace certain words (eg. slurs) with other words, including randomized options.
- You can use them for strict moderation, or for comedic effect. You can even chain random replacements, useful in making silly madlibs or randomly generated text.

## Report Notification indicators
- Will bounce when a new chat report has come in.
- This is only visible to staff.
- Notifications are cleared by visiting the Reports section of the chat room.
- Report icons will only show up for admins and moderators of the chat.
- You can see who reported the comment by hovering over the icon and clicking on the number that appears next to it.

## Account Staff
- So you can now add staff to help you with almost all creator functions!
- Staff can do almost everything you can do, except things in the personal settings area (eg. they cannot manage your personal account details and add more staff).
- Staff can help you moderate your chat. They can delete messages and give users temporary timeouts / bans.
- Staff can change your profile and chat settings, (eg. filter lists, chat emoji, block users)
- I have not added specific roles yet. That will come in a patch update shortly.
- So for now, only add staff that you trust implicitly.
- If you add staff to help you moderate chat, I strongly suggest you come up with a very clear policy around how/when you take those actions, to avoid confusion and needless drama. 
- Creator settings have moved under the Profile tab on user profiles. They will only be visible to you and staff members.

## Rules change
- Since you have more control, the rules around what types of communal behavior are OK have been modified accordingly. [Please visit the rules page to see the latest rules](/en/rules).
- Because users have effective tools to self-govern, every show can now make decisions about what types of legal behaviors are acceptable.
- If you do have specific rules for your chatroom, please add them to your Chat Welcome screen so users are fully informed before entering chat.
- If you have specific language you don't allow, please use the Filters list as well.

## Showreel improvements
- Global showreels have been added. You can access this in the footer or by clicking the ${ssr_data_tribe?.brand_name} logo.
- You can also now filter this by [just the shows you follow](/showreels?sort=follow). You can find this option on the global showreels page. 
- Added names and spinning icons to the Showreels.

## Podcasts
- Showreels can now be accessed through your favorite podcast player. 
- You can get an RSS feed of user profile content, of all published content on the site, and just content you follow.
- To get access to just content you follow, get the special link from Settings -> API -> Podcast RSS Links (must have beta enabled)

## Misc Fixes / Updates
- User Sorting on homepage and directory now possible by last broadcast time.
- Verified users can no longer change their usernames. You'll need to message ${process.env.NEXT_PUBLIC_EMAIL_SUPPORT} for help if you want to do this.
- Stopped the double confirm from popping up on the Settings pages.
- 404 user profiles are now laid out like actual profiles for a less jarring experience and better search indexing in Google.
- Javascript errors (like mistakes from poorly formed markdown code in chat) will now show up inside a user-friendly error message instead of breaking the whole screen.
- New menu navigation dropdowns - to lay the foundation for adding in search and notifications. Click the logo in the navbar to quick access global pages.
- Save and report icons are now located next to the exact line that was saved instead of the top level.
- Changed profile dropdowns back to click (previously was hover)
- Made content pages even more SEO friendly, and implemented serverside caching on major traffic points
- Cards on twitter, discord and social media will now import host avatars and descriptions.
- broke the settings code down for performance on slower devices
- Fixed bug preventing player from showing up on certain recordings.
- Discord @everyone alerts are moved to a 24 hour cool down period.
- Fixed issue where viewing number of chat message likes would refresh chat.
- Fixed chat scroll issue impacting Firefox. Required a chat scroll rewrite. Hope it doesn't cause new bugs!
- Shortened the number of chat messages to 50 so extra memory issues doesn't hurt older browsers.
- Chat can now be paused to Like messages again.
- tweak to mobile nav menu so it doesn't use an ugly html form anymore
- mobile navigation has some subtle animations
- Stopped certain forms from using the browsers autocompletion data.
- Cut down the number of database calls during login processes, which might have been contributing to occasional stalled requests on slow connections.
- Patched a hole where users can sometimes be assigned the same randomly generated username at account creation, which then breaks the onboarding experience.
- Made the account creation process generated serverside instead of clientside, so there will be less errors during that process.
- fixed odd logout redirect issue.
- moved all settings data serverside for better responsiveness.

OK whew! I'm going to sleep for a while. Please let me know if anything is acting all funky. Thanks, mom!

## Canaries hatched since ${ssr_data_tribe?.brand_name} launched: 0
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,
{
id: 25
,v:`0.6.5`
,crdate: `5/30/2022`
,slug: `profile-showreels`
,headline:`Showreels on Profile Page`
,bodytext: 
`
This was going to be part of a bigger release, but I am breaking it up as mo features = mo problems.

So in this minor release you get:

## A bunch of misc updates and tweaks
- Major changes to under the hood performance of the profile pages to help them get cached. They should perform much faster now.
- Fixed a bug that wasn't allowing embeddable players to play and made it work on thin layouts.
- Temporarily disabled new follower notification emails. I'll re-add this back as an option after I redo notification options.
- Added showreel previews to profile pages
- Added a preview of YouTube clips to the profile page.
- Showreels will now export as mp3 files moving forward, when downloaded.
- Fixed bug preventing users from listening to a second live show after the first live show ended (without requiring a refresh)
- Fixed usability issue where active chats would move too fast to like a comment. Now if you scroll off the bottom, chat will remain still so you can like comments.
- Fixed the chat timer issues. Finally.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,
{
id: 24
,v:`0.6.4`
,crdate: `5/13/2022`
,slug: `chat-speed`
,headline:`Chat speed overhaul`
,bodytext: 
`This isn't new news per se, but I never formally announced this in patch notes since it had a bunch of bugs I wanted to fix first. 
I think most of them are now fixed.. so we can officially "release" these changes:

## Chat overhaul
- Most of this is behind the scenes, but chat was upgraded to be virtually realtime so it scales with larger shows.
- Last night we had a show with 45+ folks in it (and 91 total listeners) and it worked swimmingly.
- For tech nerds: We completely rebuilt chat to use websockets, so true real time data transfer can take place. I'm going to continue to make this go even faster.
- Fixed a number of related bugs (message timers being incorrect & private messages not showing up)
- We expanded number of chat messages stored in local memory to 100
- You can now see the breakdown between guests watching chat and members sitting in chat.
- Todo: update stats counter with guests total

## Misc
- Stats: fixed a bug that was undercounting the number of guests listening to a broadcast (would often cap guests at 1)

## Some quick stats
- 11 users have now crossed the 100 follower threshold, with 3 of them above 200. Crazy!

## Coming Up Next
- Profile Overhauls! Let's get those showreels front and center
- Global showreels section
- New broadcaster guide
- Showreel listener stats
- Tiger wrestling

## Thanks!!
- Thanks to our newest Patreon Luxapol!
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}, 
{
id: 23
,v:`0.6.3`
,crdate: `5/2/2022`
,slug: `show-reels-and-global-player`
,headline:`Showreels and global player`
,bodytext: 
`## Major update! 
Although I'm going to be honest and tell you that I think this still needs a lot of polishing and usability improvements.
At the same time, I need to get this one out the door so I can focus on improving chat's responsiveness, which is becoming more and more of an issue.

## Global player
- Now when you browse around the ${ssr_data_tribe?.brand_name} website, you will still be able to listen to live shows without accidentally cancelling the stream.
- It sounds simple, but this was ridiculously complicated to get working.
- You can expand the player for more options and broadcast details.
- This also works for recorded showreels you listen to. 
- Note that only reels support Time Seeking forward / backward. Live shows will only play live audio moving forward, to keep listeners in sync with hosts and be less confusing. 
- Speaking of showreels....

## Showreels
- Listen to old shows! You can find showreels under Clips on user's profile pages.
- Hosts can publish their previous ${ssr_data_tribe?.brand_name} shows to make them available to listen.
- Hosts can download mp4s of past shows (if they enabled recording). This will only work for shows that used the beta broadcasting platform.
- Hosts can manage and publish showreels under Account Settings > Broadcast Settings > Manage Showreels: ${ssr_data_tribe?.server_website}/app/settings/broadcast?panel=broadcast&page=1&sort=date&dir=down&q=#broadcast
- I will be adding a way to upload old non-${ssr_data_tribe?.brand_name} audio files to Showreels in the very near future.

## Broadcast titles and other info
- Broadcasts can now be titled and given a description.
- Co-hosts and graphics coming soon.
- If you use Rocket Broadcaster or other third party apps you cannot currently preset your broadcast title. 
- But you can edit it while you are live right on the chat page (click the edit icon near the broadcast stats when they show up). 
- I'll improve this in the future.

## Web broadcaster improvements
- If you have already saved some upcoming broadcasts, you can now choose them at the point of broadcast (web app only).
- You'll see a list of any unaired broadcasts in the web editor when you trigger it. 
- You can also add broadcast details at run time. 

## Remote Player Builder
- I added a simple form to the remote player settings, to make it easier to build and preview your own embeddable remote player.
- You can build your remote player here: ${ssr_data_tribe?.server_website}/app/settings/broadcast?panel=userembeddableplayer

## Other misc items
- Fixed giphy search bug that was making some strings not get searched properly
- fixed notification verification.
- There's a new visual banner that takes over a page's header while a show is live. If you send someone to your page, there's now a giant banner with an unmissable play button. You can X  it off to access the normal profile header.
- The save reminder warning that appears in the header in the accounts settings section is now clickable as an additional save button, to save you time.

## Up Next:
- Making chat go real time!
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,{
id: 22
,v:`0.6.2`
,crdate: `4/12/2022`
,slug: `upcoming-shows-on-homepage`
,headline:`Upcoming shows on the homepage`
,bodytext: 
`## Upcoming shows are now on the homepage
- Currently shows the next day's worth of shows. I'm sure I'll be tweaking this a lot over time.

## More stats tracked [beta only] 
- Beta stats are now tracking likes, comments and stickers (gifs) added

## Icecast end of life date = April 30
- Speaking of beta, move over already please! 
- I'm not going to renew the server at the end of April, so please move over immediately. 
- I promise it takes 5 minutes total to make the changes in BUTT and other tools. 

## Removing some listings from homepage
- I'm going to be doing some housekeeping to take down non-active hosts. 
- I'll put them back later when these are active again.

## YoutTube cache
- Youtube clips were going over our API quota, so I'm caching the first page of clips for 12 hours to help stop that from happening. 
- If you upload a clip and want to override your cache, there's now a refresh button that only owners can see, on their clips page (next to the page title)

## Discord tweaks
- Only homepage featured users can trigger global alerts in the ${ssr_data_tribe?.brand_name} show-alerts channel moving forward. 
- Users not on the homepage will still alert their specific followers.

## Embeddable Player
- You can now link a few folks together in the embeddable player instead of just embedding one user at a time.
- If any of the users in the list are live, the live show will automatically be displayed to the user.
- You can toggle through multiple users with the new paging control that shows up when multiple results are passed in.
- This is useful if someone wants to promote a bunch of hosts at once (like what currently happens on worldofprankcalls.com). 
- This should help some streams get more external visibility to new audiences.

## Other incremental tweaks
- Schedule settings can now be found under Account settings -> Broadcast Settings: ${ssr_data_tribe?.server_website}/app/settings/broadcast?panel=userschedule
- Some modal windows now have the background darkened out, to make it easier to see what is in focus in the modal and what is not.
- Added a comprehensive dynamic sitemap to help drive more SEO to user's profiles so they capture more listeners.
- Added a menu placeholder for reels (coming next)
- Major fix to how new user accounts are created that should hopefully fix a bug where some accounts randomly dont have avatars.
- All images uploaded by users are now stored in a user-specific folder (eg. avatars and show banners currently). I'll allow users to manage these at a later date.
- Switched to a hover state for menu dropdowns on user profiles. Feeling cute, may remove this later.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
},
{
id: 21
,v:`0.6.1`
,crdate: `4/8/2022`
,slug: `giphy-chat-integration`
,headline: `Giphy Chat Integration`
,bodytext: 
`## Giphy integration in chat
- Now you can add gifs to chat without needing to know markup
- The library will show content based on the message you type
- It will match the default emoji the host has chosen if the show message is blank
- I'll add proper search soon.
- This option will only show up in chats where the host has markdown is enabled.

## Embeddable Player Upgrades
- External listens are now tracked and added to stats (BETA users only)
- You can now add multiple users to a single embeddable player and when one of them goes live they will appear.
- Just pass a comma separated string of usernames. This is useful for groups with multiple shows.
- Hosts can opt out of having their streams appear in all external embeddables.
- To grab an embeddable or change your settings go here: ${ssr_data_tribe?.server_website}/app/settings/broadcast?panel=userembeddableplayer

## SEO optimizations
- Profile pages and key site pages are now cached for ideal indexing by Google. 
- Let's get that Google juice flowing so we can bring you more audience!
- You may notice some pages feel faster to the user as well.

## Just for Nerds: API Query Endpoint Update
- You can now search multiple users with a single call.
- Just pass in a comma separated list of broadcaster usernames and you will get a response back on all of their statuses with a single call.

## Incremental changes
- Hosts are now visible in schedule details, even if other users are not checked off.
- Verified users can now add embeddable html content to their profile without additional approval needed.
- You can now sort alphabetically by username in the Directory.
- Schedules now show a link back to the homescreen.
- Password bug is now fixed.
- Profile screen settings tabs now have unique links. This will make it easier for me to link to new settings as I add them.
- Volme faders in web broadcaster now respond to single taps, instead of just drag movements.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
},
{
id: 20
,v:`0.6.0`
,crdate: `4/5/2022`
,slug: `schedules-multi-inputs-and-more`
,headline: `Schedules, Multi-inputs and more!`
,bodytext: 
`
# **Version 0.6 is out!**

## MAJOR broadcaster upgrades [Beta only]

**Multiple inputs in the web broadcaster app!**
- You can now add up to 5 inputs when using the web broadcaster. 
- I will possibly lower it in the future, but for testing purposes, add as many as you like.

**Stream audio from specific apps and/or browser tabs.** 
- This is extremely experimental and support varies drastically in different browsers and operating systems. 
- I'll share a separate post listing support later.

**Independent volume control / monitoring per channel.**

**Settings memory - When you tweak a setting, it will be remembered on that device for next time.**

## Schedule features added
- Go add your details! ${ssr_data_tribe?.server_website}/schedule and click the Add New button. 
- You can also add new schedule details on your profile's schedule page.
- Add show information, timing and even a banner graphic.
- Add co-hosts, regular guests and production crew. 
- Any other folks you add will automatically import your schedule on their pages, helping everyone cross-promote.
- The new global schedules page lists all shows in one location.
- You can also filter it to just show the shows you follow.

## YouTube clips change
- Now only one video can play at a time and it will do so in a larger view above the clips. 
- Click any thumbnail to activate.

## Pre and post-roll capabilities [Beta only] [Testers needed]
- You can now auto-play a prerecorded clip before and after your broadcast. 
- It will also be auto-added to the recording.
- This will only be enabled on a per-account basis for now.
- If you want to try this out, please let me know. It's not in the interface officially yet.

## Broadcasters using the old icecast: Moveover to the beta, please!! Git git!
- If you are a broadcaster not yet opted into the beta, please start migrating! 
- It's really simple and takes just a couple of minutes to test it out. 
- If you can't get it to work you can always undo it and go back (just do not change any password / api tokens).
- DO NOT WAIT UNTIL 1 MINUTE BEFORE YOUR NEXT STREAM TO DO THIS. 
- Give yourself enough time to set it up, test it and troubleshoot. Thanks!

## UP NEXT
- Upcoming shows teased on the homepage
- Reels profile section
- Add show staff to help you monitor your chat. Some of the bigger shows rely on this
- I dont know some kind of rabbit-horse machine thingy. Just wanted to see if anyone actually read to the bottom.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,{
id: 19
,v:`0.5.9`
,crdate: `4/1/2022`
,slug: `prank-cats`
,headline: `PrankCats.com`
,bodytext: 
`## Meow Meow Meow Meoooooow
- Meow meow!
- Meow Meow Meow Meoooow Meow Meow Meoooow
- Meow Meoooow Meow Meow Meow Meow Meow Meoooow Meow Meow Meoooow Meoooow 
- Meow Meow Meow Meoooow Meow Meow Meoooow Meow Meow Meow Meoooow Meow Meow Meoooow

## Rowr purr hissss.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
},
{
id: 18
,v:`0.5.8`
,crdate: `3/26/2022`
,slug: `beta-stats-tracking`
,headline: `Beta Stats Tracking`
,bodytext: 
`## Listener stats are now tracked for the beta opt-in (only).
- For now it's just a listener, guest and chatter count.
- I'll be building a bunch of additional data reporting views for hosts to better understand show performance. 
- You'll be able to see these stats next to / under the chat while a broadcast is live (beta users only).
- You'll also receive an email immediately after the broadcast with a performance summary.

## More Follower Control
- You can now control who follows you.
- Settings include open (anyone can follow), open to non-blocked users, by request, or (private) only people the host already follows.
- To modify this setting, please go to Account Settings -> Profile Customization -> Follower Settings.
- Please report bugs in our discord. I could not test it as much as I would have liked.
- This setting introduces a few additional screens (eg. a screen to manage follow requests).
- I condensed the followers navigation into a single tab. 
- Only the host can see and manage follow requests (incoming and outgoing) and a list of ignored requests.

## Blocked Users 
- Blocked users will not be able to receive show alerts. 
- Blocked users cannot follow the hosts who blocked them (unless the host has the settings completely open).

## Chat upgrades

Chat filters
- There's now a filters button in the chat header. Clicking it will show you all available options (eg. all, dm's, saved, etc...)

Save chat messages
- In the options menu for each chat message you'll now have a Save option. You can access saved messages from your chat history (it's one of the filters).

Reported chat messages
- Host only. Reports from other users will show up here. 
- I dont yet have a way to proactively alert hosts when a new report comes in, but that will fast follow.

## Beta Transition
- NOTE: I'll be reaching out to all icecast users to help move everyone into the beta this week.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,{
id: 17
,v:`0.5.7`
,crdate: `3/23/2022`
,slug: `embeddable-player-and-chat-fix`
,headline: `Embeddable and Chat fix`
,bodytext: 
`
## Embed works with Beta Broadcasts
- Feel free to start using the beta, if using the embeddable on your website was holding you back!
- You can find the Embeddable Player under Account Settings > Broadcast Settings > Embeddable Player.

## Settings confirm prompt
- If you leave an area in settings before saving your work, you will now receive a warning prompt asking you to confirm.
- You will also see a large reminder banner when you have unsaved changes.
- Settings save buttons were enlarged and flash subtly when you have unsaved changes as well.

## Markdown now works in Profile Descriptions
- Anything you can do in chat you can now do in your user descriptions.
- You will se a Preview and a link to a markdown guide below the description.

## Chat Markdown toggle
- Hosts can now choose whether or not to enable Markdown code for their users in chat.
- Markdown allows users to post links, images and rich text in chat.
- Chat is WAY more fun with it enabled, but obviously there's a risk of abuse.
- The cool thing about markdown is that unlike parsing html, even when it's disabled, everything is still easily readable.
- This toggle can be found under Account Settings > Profile settings > Chat Customization

Misc fixes
- Images in chat/descriptions have a max height of 200 pixels.
- Chat will continue scrolling while at the bottom when images are posted. 
- Contribute page now opens in a new window so generous users dont lose the stream they are listening to.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,{
id: 16
,v:`0.5.6`
,crdate: `3/19/2022`
,slug: `iOS-player-fix`
,headline: `iOS player fix`
,bodytext: 
`
## The beta player now works on iOS and Android
- I hope so anyways! I'll run a test of it tonight on my show stream.
- Note: The embeddable player some hosts have put into their website does not yet work with the beta. I expect that to be resolved in the next few days.
- So if you're relying on the embeddable, do not opt-in to the beta yet.

## Tip Jar tweak
- After your stream ends, the tip jar button will now wiggle for a few minutes as a gentle reminder for people to tip their host.

## Broadcast App tweaks
- Added a javascript prompt if a user tries to close the window, just to avoid accidental closes. 
- The text on the prompt doesn't make sense, but is not customizable unfortunately (browser security concern).

## Recommendation list pruning
- We now have a lot of users on the homepage / new follow recommendations list and I will be shortening it a bit to only show active hosts / or hosts bringing in new audiences to ${ssr_data_tribe?.brand_name}. 
- This is just to make sure new users easily find fresh content or the hosts they joined the site to find in the first place.
- Rest assured that I'm happy to add anyone back to the recommendations list and help promote them again when they are ready to go live.

# Chat whisper replies
- You can now click the Private icon in a whisper to you and it will prepare the message to reply privately.

## Misc
- Fixed: Follow tracking was not working in Firefox for certain users.
- Big chat database call /speed improvements under the hood (I hope!)
- Added better loading state guidance and ux indicators for users. Should help with slow mobile connections
- Accessibility tweaks (higher contrast colors)
- Meta upgrades: SEO tweaks to custom page titles & Google Analytics events
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,{
id: 15
,v:`0.5.5`
,crdate: `3/17/2022`
,slug: `chat-private-notifications`
,headline: `Chat Private Notifications`
,bodytext: 
`
## Private Chat Messages 
- You can now message the host and other users privately while in the chat room. 
- To send a private whisper, click the lock icon next to the chat message box. 
- To specify a user in chat, put @username at the beginning of the message.
- If you don't specify a user, the host will get it.
- Users do not currently get alerts, so they need to be in chat to see it.

## Other Chat tweaks
- You can now use markdown to format your message.
- You can now click the likes # to see who liked the chat message.
- Lowered the flood spam detection down to half a second, so you can type faster between messages.
- Fixed that pesky follower bug in chat.
- Modal windows now open properly as well.

## Show Alert Double-Dipping fix
- Show alerts will only go out if a host hasn't previously gone live with sent notifications in the last hour.
- I might revisit that timing amount later.
- This prevents false starts / stream restarts from spamming up Discord and other channels.

## Rules page added
- Linked in the footer. Please follow them. Thanks!
- ${ssr_data_tribe?.server_website}/en/rules

## Contributors page added
- Thanks a ton to everyone who is helping get ${ssr_data_tribe?.brand_name} off the ground!
- I listed all current Patreons on the bottom. 
- ${ssr_data_tribe?.server_website}/contribute

## BUTT bugs fixed [BETA opt-in feature]
- We identified a bug impacting BUTT users who opted-in to our new beta broadcast features.
- Users of the previous system were not impacted. 
- The bug is now corrected, so you can use BUTT for the new broadcasts moving forward if you are using the beta opt-in features.
`
,author: ssr_data_tribe?.brand_author
,md_file:`0`
}
,{
author: ssr_data_tribe?.brand_author
,id: 14
,crdate: `3/16/2022`
,md_file:`0`
,v: `0.5.4`
,slug: `web-encoder-beta`
,headline: `Web encoder Beta`
,bodytext: 
`
Major update!

## New beta feature toggle
- This is necessary if you want some of the features mentioned below.
- To activate early beta features, just go to the bottom of your account settings -> Advanced Features (beta).
- If you already have been broadcasting to ${ssr_data_tribe?.brand_name} DO NOT toggle this without talking to me first before your next stream.
- Some configuration settings have changed with the new architecture that we use to enable web app streaming and recording.
- Your previous settings will remain intact until you toggle this and I want to make sure that the transition is smooth for you.

## Web broadcaster [Beta opt-in]
- You no longer need to use a third party app to stream to ${ssr_data_tribe?.brand_name} (you still can though!). 
- I ran a 3-hour broadcast test of this on Monday and it was super smooth.
- I also added a test mode button to the bottom so you can test going live with it without alerting people (or needing to fiddle with your settings). 
- To try it just click the broadcast button anywhere on the site.
- I need to enable your account for you, but this is more to make sure you understand the rules before I do so.
- It only has 1 input at the moment. A second one is coming soon.
- You can watch a set up video here to get started. https://youtu.be/ZYPxISqyO2Y

## Stream recording [Beta opt-in]
- You can now record your streams automatically. 
- The 3 settings are: 
* ON [Public] - this will auto publish the recorded stream to your profile after it's over.
* ON [Private] - this will record, but you will need to manually publish it before anyone else can see it.
* OFF - Your stream is not recorded at all.
- The default setting is ON [Private] (meaning nobody but you will be able to view it).
- To edit this go to Account Settings -> Broadcast Settings -> Recording Settings.
- The page to view your recordings is not quite ready yet (I had a computer crash while working on it today). :(
- But anything recorded moving forward will be ready when it's live.

## Chat control
- You can now set chat to auto-disable new posts when you go offline. 
- Account Settings -> Profile Settings -> Chat Customization

## Whole bunch o' performance tweaks.

Up Next
- Schedule (finally!)
- Recording Reels
- Broadcast Stats
`

}
,{
author: ssr_data_tribe?.brand_author
,id: 13
,crdate: `3/11/2022`
,md_file:`0`
,v: `0.5.3`
,slug: `settings-overhaul-and-timers-oh-my`
,headline: `Settings overhaul and timers, oh my!`
,bodytext: 
`
## Broadcast Timers
- Now you'll see the broadcast time when a user is Live.
- I might do countdown timers as well, after implementing schedules, depending on page performance impact.

## Settings overhaul
- The wall of settings was getting out of hand so I chunked it up and grouped it into more intuitive categories.
- I also aligned the settings page navigation with the rest of the site.
- Broadcasting Instructions and API have been merged into one single document with a toggle that lets you pick directions.
- Sorry to anyone who now has to figure out where everything is moved around to.

## Query API added
- I added more formal documentation if you want to query our servers to know when shows are online or offline for your own applications.
- You can generate an access token and then follow the general API instructions to query us.
- This is located under Settings as well. 
- Note that with this change I moved the stream credentials under Broadcast settings.
`

}
,{
author: ssr_data_tribe?.brand_author
,id: 12
,crdate: `3/10/2022`
,md_file:`0`
,v: `0.5.2`
,slug: `custom-chat-emoji-and-likes`
,headline: `Custom Chat emoji and likes!`
,bodytext: 
`
## Custom Chat Like Emoji
- Everyone can now choose a custom chat emoji theme in lieu of cactuses (our version of chat hearts) for their chatroom.
- To set your emoji theme, go to your chat preferences in your settings and pick the emoji for your chat.

## Chat Message Likes
- Users can now like individual messages in chat.
- When a message is liked it will increment a counter.
- The comment like icon will be identical to the custom chat emoji the show host has picked.
`
}
,{
author: ssr_data_tribe?.brand_author
,id: 11
,crdate: `3/9/2022`
,md_file:`0`
,v: `0.5.1`
,slug: `follow-notifications`
,headline: `Follow Notifications`
,bodytext: 
`
## Follow Notifications
- If you have notifications enabled you will now receive an email when a user follows you. 
- This is tied to your overall notifications, but I will make a separate setting to control this in the next update.
- To prevent notification spamming, you will only receive a single email per follower. So if a user follows, unfollows and refollows you, you will not be notified twice.

## User Discovery
- New user directory feature here: ${ssr_data_tribe?.server_website}/directory
- You can sort users by follower count and join date. More options to come as I add new trackable stats. Search is coming soon too.

## Sped up chat
- Tracking followers across the site is a bit of an expensive operation and was slowing down chat.
- So I temporarily disabled it when you click on a users' name in chat.
- I will put this back soon.

## Lot of under the hood bug and performance fixes
- Knowing me, I probably introduced a bunch of other bugs in doing so. :)
- Please let me know if you see anything funky. You can report bugs in our discord.

## Up next:
- chat message likes
- event schedules & upcoming
- Improved User discovery
- Broadcaster stats
`
}
,{
author: ssr_data_tribe?.brand_author
,id: 10
,crdate: `3/2/2022`
,md_file:`0`
,v: `0.5`
,slug: `embeddable-player`
,headline: `Embeddable Player`
,bodytext: 
`
## Responsive Embeddable Player
- Add a player to your website or wherever you want!
- You can grab the code for it under Account Settings > Embeddable Player
- Has togglable dark mode

## Tip Jar Button for Show Hosts
- Show hosts can add a prominent customizable tip jar button to the menu bar
- It links to the support link of your choice
- This will appear on top of the profile on every page
- To access it, go to Account Settings > Tip Jar Customization

## Broadcast Status API
- We now have a public API to check if specific users (or any users) are broadcasting.
- Check a single user: ${ssr_data_tribe?.server_api}/api/public/v0/user/status?name=${ssr_data_tribe?.brand_author}
- Check all users: ${ssr_data_tribe?.server_api}/api/public/v0/user/status

## Global Dark Mode toggle
- You can toggle Dark Mode (the simplest place is in the footer). You do not need to be logged in to do this.

## Profile enhancements
- When a user goes live, a massive chat notification appears throughout the profile.
- Profile menu is now responsive, and becomes a select menu on smaller screens.
- Profile pages now have a sidebar on larger monitors.
- Rotary loaders when data is loading

## Chat enhancements
- Users will be alerted when new messages appear if they are currently not scrolled down.
- Chat flood limit is lowered to 1 second.
- Active Users can be scrolled on top of the chat window.
- Reporting now works
- Follow buttons now work correctly in chat.
- Cactus buttons no longer clears the chat form.

## More Broadcast credentials
- Some more hosts are now added to the homepage. Go check them out to be notified when they are live!

## Up Next
- Show Schedules!
- Some new broadcasting tools`
}
,{
author: ssr_data_tribe?.brand_author
,id: 9
,crdate: `2/24/2022`
,md_file:`0`
,v: `0.4.5`
,slug: `dark-mode`
,headline: `Dark Mode!`
,bodytext: 
`
## Dark Mode
- We now have dark mode capabilities. You cannot manually override this (yet), but it will match whatever preferences are on your computer or device. To try it out, just make sure your device is currently in dark mode and refresh.
- This feature was a lot of grinding, but in retrospect I'm happy it's here. We do have a lot of late shows, so I'm happy your eyeballs may stick around a while longer.

## Broadcast credentials
- OK - now that we know that broadcasting works really well I'm opening up broadcasting to a few more folks to run their shows on. To request access, just shoot me an email at ${process.env.NEXT_PUBLIC_EMAIL_SUPPORT} or even better DM me on Discord and I'll activate your credentials.

`
}
,{
author: ssr_data_tribe?.brand_author
,id: 8
,crdate: `2/21/2022`
,md_file:`0`
,v: `0.4.4`
,slug: `discord-notifications`
,headline: `Discord Notifications`
,bodytext: 
`
## Discord General Alerts
- Have your own Discord server? The Show Alerts announcements can now be added to your discord. Visit the channel and click Follow.

## Discord Direct Message alerts
- In addition to email and SMS notifications as an option, we now have Discord direct message notifications when a show you follow goes online. I LOVE this option because it's free for us to send out and it enables you to receive push notifications on your mobile device if you have push notifications enabled for the discord app. And because it's specific to whomever you follow, you won't receive alerts you don't want to see.

## Chat can be expanded
- In the top right corner of all chatrooms you'll now see an expand button. You can toggle it to go full screen (or close it back to normal).
`
}
, {
author: ssr_data_tribe?.brand_author
,id: 7
,crdate: `2/18/2022`
,md_file:`0`
,v: `0.4.3`
,slug: `disable-outgoing-notifications`
,headline: `Disable outgoing notifications`
,bodytext: 
`
## Control outgoing notifications
- Minor update - you can now disable notifications from going out to your followers when you broadcast (under Account Settings -> Notification Preferences -> SEND notifications to your followers when you go live?
- This is useful if you want to test out your stream connection setup without annoying everyone. 
`
}
, {
author: ssr_data_tribe?.brand_author
,id: 6
,crdate: `2/17/2022`
,md_file:`0`
,v: `0.4.2`
,slug: `more-profile-power`
,headline: `More Profile Power`
,bodytext: 
`
## More embed control
- Toggle whether or not embedded content appears on your profile.
- Embedded content access now has to be requested due to security concerns. I'll add it if I trust you, I just couldn't leave it open by default.

## More chat control
- Hide chat from your profile.
- Go read only (so only the profile owner can post).
- Block users or give them a 1 hour timeout.

## Discord notification bot
- This is an early bot that notifies @everyone in the show-alerts channel in our discord, whenever someone broadcasts on our platform.

## Design tweaks
- New custom loading rotary spinner appears throughout the site.
- Favicons now appear alongside links automatically (when available).

## Coming up next
- v.05 (??)
- Notifications (web push & discord direct messages)
- Ability to schedule event calendars
`
}
,  {
author: ssr_data_tribe?.brand_author
,id: 5
,crdate: `2/16/2022`
,md_file:`0`
,v: `0.4.1`
,slug: `clips-ahoy`
,headline: `Clips Ahoy`
,bodytext: 
`## Clips update
- Clips are now sorted by recency
- You can page through results.
`
}
,
{
author: ssr_data_tribe?.brand_author
,id: 4
,crdate: `2/15/2022`
,md_file:`0`
,v: `0.4.0`
,slug: `open-beta`
,headline: `Open Beta`
,bodytext: 
`## Open Beta
- Anyone can create accounts now.

## Simple chat room
- All user profiles have a native chatroom on them.
- Users can also post a cactus emoji. I dont know why.
- Users can block other members from chatting in their room.
- More permissions to control how they are used (or be completely hidden/disabled) will come shortly.
- Users can see who is currently live on their chat page.

## User discovery on homepage and onboarding 
- Select show hosts are recommended on the homepage and during user signup.

## Discord 
- [Join the discord](https://discord.gg/uws4KQ54ea) to let us know about bug reports and make feature requests: https://discord.gg/uws4KQ54ea
- Joining will also enable you to receive notifications on all shows you follow (soon).

## Coming soon
- v.04.2
- A few more settings to better control the profile / embed experience.
- Clips pagination and sorting (if youtube offers it)

- v.05 (??)
- Notifications (mobile web push & discord)
- Ability to schedule event calendars`
}
,
{
author: ssr_data_tribe?.brand_author
,id: 3
,crdate: `2/13/2022`
,md_file:`0`
,v: `0.3.0`
,slug: `sms-and-email-notifications-are-live`
,headline: `SMS and Email Notifications are live`
,bodytext: 
`## Notifications
- Users can receive show alerts for shows they follow via email and SMS notifications.
- Users have to opt in to receive them, which includes verifying your email or phone number. This wasn't fun to build, but I'm glad it's done!
- Browser push, whatsapp and discord notifications coming soon!

## Icecast API
- Simple API for broadcasting your show status to our server via your icecast stream (though technically you can do it manually to trigger a notification to your followers).
- It's located at the bottom of your profile settings (temporarily).

## Updated logo!
- hopefully this looks more like a rotary phone now

## Consolidated Profile page
- Much easier to navigate and get feedback on saves

## News 
- Feature update notes are now linked in the site footer and dashboard.

## TODO / Coming next
- v.04 (Monday!)
- Simple chat room
- User discovery on homepage 

- v.05 (??)
- Notifications (push & discord)
- Ability to schedule event calendars

I'm aiming to do a test stream on my show on Monday night. Hope you can make it to help test!`
}
,
{

author: ssr_data_tribe?.brand_author
,id: 2
,crdate: `2/9/2022`
,md_file:`0`
,v: `0.2.0`
,slug: `working-icecast-and-profiles`
,headline: `Working Icecast and profiles`
,bodytext: 
`## Profiles
- Users can create and customize profiles
- Users can add third party embeds (eg your mixlr player) and custom links to profiles
- Users can follow each other
- Users can add youtube playlist ids and it imports the clips

## Icecast Integration
- Users can use our icecast integration (contact me to use it and ill send separate credentials to you) or use their own (contact me for API access).
- When you use either scenario it pings our API and lists you as online everywhere on the site that your avatar appears.

## TODO / Coming next
- Notifications (this will be email and push via a web app)
- Simple chat room
- Ability to schedule event calendars
- User discovery list - I'll add all of you initially

Please create a profile and let me know how it goes!`
}
,  {
id: 1
,v: `0.1.0`
,slug: `basic-framework`
,headline: `Basic Framework`
,bodytext: 
`* Working auth!
* Working database crud for new data types
* working profile management `
,author: ssr_data_tribe?.brand_author
,crdate: `1/30/2022`
,md_file:`0`
}
,
{
id: 0
,v: `0.0.0`
,slug: `hello-world`
,headline: `Hello world`
,bodytext: 
`- Kicking off this project!`
,author: ssr_data_tribe?.brand_author
,crdate: `1/27/2022`
,md_file:`0`
}
]