export function DashboardHeadline({
  
    headline
   ,selected=false
 }){
     return (<>
               <div className={`${selected 
                               ? " text-white"
                               : "  text-gray-300"
                             }
                               flex flex-1 md:items-center md:justify-start md:inset-y-0 md:left-0 w-full
               `}>
                 <h2 className="inline-block text-lg pl-4 whitespace-nowrap mr-2">
                   {headline}
                 </h2>
               </div>
     </>)
   }